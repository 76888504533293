import React, { useEffect } from 'react';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const LayoutMicro = ({ children, microSitename, location }) => {
  useEffect(() => {
    document.body.classList.remove('menu-open');
  }, []);
  return (
    <main className="grid-container">
      <div className="global-wrapper">
        <Header microSitename={microSitename} location={location} />
        {children}
        <Footer />
        <div className="hidden my-40 lg:text-28 text-22 max-w-515 inner-page-banner  pb-0 mt-0 mt-30 lg:mt-60 margint-0 paddingb-0 my-30 lg:my-60"></div>
      </div>
    </main>
  );
};
export default LayoutMicro;
