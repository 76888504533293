import { graphql, Link } from 'gatsby';
import React from 'react';

const onCategoryChange = (newCategoryId) => {};

const PressReleases = ({ module, i }) => {
  return (
    <>
      <section
        id={module.extraId !== '' ? module.extraId : ''}
        className={`lg:py-20 py-30 ${
          module.extraClass !== '' ? module.extraClass : ''
        }`}
      >
        <div className="container-fluid-md">
          {/* <div className="container m-auto">
            <div className="filter-list mdscreen:pb-10 mdscreen:border-b-[1px] mdscreen:border-b-[#666366]/40 text-center lg:mb-60 mb-30 relative">
              <select
                onChange={(e) => {
                  onCategoryChange(e.target.value);
                }}
              >
                <option value="all">Latest Press Release</option>
              </select>
            </div>
          </div> */}
          <div className="bg-white lg:py-40 py-20 lg:px-100 px-20 shadow-card">
            {module.pressReleases.map((item, i) => {
              return (
                <div className="flex items-center border-b-[0.8px] border-opacity-40 border-dark justify-between py-20">
                  <span className="text-black-100 tracking-004em text-26 smscreen2:text-14 smscreen2:pr-10 font-heading font-400">
                    {item.pressReleaseName}
                  </span>
                  <div className="download-icon">
                    <Link
                      to={item.pressReleaseLink}
                      target="_blank"
                      className=" flex items-center "
                    >
                      {item.pressReleaseLinkType === 'download' ? (
                        <>
                          <img
                            className="max-w-[15px]"
                            src="/images/download.png"
                          />
                          <span className="text-black-300 uppercase text-12 pl-10">
                            Download
                          </span>
                        </>
                      ) : (
                        <>
                          <img
                            className="max-w-[13px]"
                            src="/images/new-tab-icon.png"
                          />
                          <span className="text-black-300 uppercase text-12 pl-10">
                            View
                          </span>
                        </>
                      )}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default PressReleases;
export const PressReleasesFragment = graphql`
  fragment PressReleasesFragment on WpPress_Flexiblecontent_FlexibleContent_PressReleases {
    # content
    fieldGroupName
    pressReleases {
      fieldGroupName
      pressReleaseLink
      pressReleaseName
      pressReleaseLinkType
    }
    extraId
  }
`;
export const PressReleasesPropertyFragment = graphql`
  fragment PressReleasesPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_PressReleases {
    # content
    fieldGroupName
    pressReleases {
      fieldGroupName
      pressReleaseLink
      pressReleaseName
      pressReleaseLinkType
    }
    extraId
  }
`;
