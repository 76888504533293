import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const PropertyHighlightRelationship = ({ module }) => {
  const [tabOption, setTabOption] = useState(
    module.accommodationListing[0].accommodationContent.grid.tabs[0].tabHeading
  );

  return (
    <>
      <section className="imgoverlay-wrapper lg:py-60 py-30">
        <div className="container-fluid-lg lg:space-y-32 space-y-14">
          {module?.accommodationListing?.map((item, index) => (
            <div key={index} className="imgoverlay-grid fade-img relative">
              <div
                id={item?.slug}
                className="anchor absolute top-[320px] mdscreen:top-[80px] left-0 z-[-1]"
              ></div>
              <Swiper
                loop={true}
                lazy={true}
                speed={500}
                slidesPerView={1}
                effect={'fade'}
                navigation={true}
                modules={[Navigation, EffectFade]}
              >
                {item?.accommodationContent.grid?.gallery?.map(
                  (item, index) => (
                    <SwiperSlide key={index.toString()}>
                      <img
                        src={item.imgixImage.url}
                        className="md:min-h-[570px] object-cover w-full"
                        width="1140"
                        height="576"
                        loading="lazy"
                        alt={item?.altText}
                      />
                    </SwiperSlide>
                  )
                )}
              </Swiper>

              <div className="imgoverlay-content lg:px-40 lg:mt-minus-100">
                <div className="bg-white z-9 relative lg:p-50  p-25 shadow-card">
                  <h3>{item?.title}</h3>
                  <div className="flex flex-wrap justify-between">
                    <div className="lg:w-6/12 w-full">
                      <div className="custom-tabs hidden mdscreen:flex mt-15">
                        {item?.accommodationContent.grid.tabs?.length > 0 &&
                          item?.accommodationContent.grid.tabs.map(
                            (item, i) => {
                              return (
                                <div
                                  key={i}
                                  onClick={(e) => {
                                    setTabOption(item.tabHeading);
                                  }}
                                  className={`custom-tabs__tab uppercase text-14 text-dark font-400 px-10 py-10 border-b-1 border-b-[rgba(229,229,229,0.8)] ${
                                    item?.tabHeading === tabOption
                                      ? 'tab-current'
                                      : ''
                                  }`}
                                >
                                  {item.tabHeading}
                                </div>
                              );
                            }
                          )}
                      </div>
                      <div className="left-content lg:pr-40">
                        <ul className="tabs mdscreen:hidden mt-15 border-black-100 border-opacity-10 inline-block">
                          {item?.accommodationContent.grid.tabs?.length > 0 &&
                            item?.accommodationContent.grid.tabs.map(
                              (item, index2) => (
                                <li
                                  key={index2}
                                  onClick={() => {
                                    setTabOption(item.tabHeading);
                                  }}
                                  className={`tab-link cursor-pointer text-dark uppercase font-400 text-12 ${
                                    item?.tabHeading === tabOption
                                      ? 'tab-current'
                                      : ''
                                  } `}
                                >
                                  {item?.tabHeading}
                                </li>
                              )
                            )}
                        </ul>
                        <div className="tabbox mt-20">
                          <div
                            id="overview"
                            className="tab-content global-list"
                          >
                            {item?.accommodationContent.grid.tabs.length > 0 &&
                              item?.accommodationContent.grid.tabs.map(
                                (item, index3) => {
                                  return (
                                    item?.tabHeading === tabOption && (
                                      <div
                                        key={index3}
                                        className="content text-[13px]"
                                      >
                                        {item?.tabContent &&
                                          parse(item?.tabContent)}
                                      </div>
                                    )
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-6/12 w-full">
                      <div className="right-content global-list lg:pl-60 relative lg:pt-0 pt-20">
                        <div className="right-content__text font-heading lgscreen:mt-20 font-300">
                          {item?.accommodationContent?.grid?.content &&
                            parse(item?.accommodationContent?.grid?.content)}
                        </div>
                        <div className="btn-custom space-x-2 mt-20 w-full md:w-auto !justify-start">
                          {item?.accommodationContent?.grid.cta && (
                            <Link
                              to={item?.accommodationContent?.grid?.cta?.url}
                              className="button button-transparent"
                            >
                              {item?.accommodationContent?.grid?.cta?.title}
                            </Link>
                          )}
                          {item?.accommodationContent?.grid?.additionalCta && (
                            <Link
                              to={
                                item?.accommodationContent?.grid?.additionalCta
                                  .url
                              }
                              className="link-btn"
                            >
                              {
                                item?.accommodationContent?.grid?.additionalCta
                                  .title
                              }
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default PropertyHighlightRelationship;

export const PropertyHighlightRelationshipFragment = graphql`
  fragment PropertyHighlightRelationshipFragment on WpProperty_Flexiblecontent_FlexibleContent_PropertyHighlightRelationship {
    fieldGroupName
    accommodationListing {
      ... on WpAccommodation {
        id
        uri
        slug
        title
        content
        featuredImage {
          node {
            altText
            uri
            title
          }
        }
        content
        accommodationContent {
          fieldGroupName
          grid {
            tabs {
              fieldGroupName
              tabContent
              tabHeading
            }
            gallery {
              altText
              title
              imgixImage {
                url(imgixParams: { w: 1140, h: 570, q: 95, fit: "crop" })
              }
              uri
            }
            cta {
              target
              title
              url
            }
            content
            additionalCta {
              target
              title
              url
            }
            image {
              altText
            }
          }
        }
      }
    }
  }
`;
export const PrivateResidencePropertyHighlightRelationshipFragment = graphql`
  fragment PrivateResidencePropertyHighlightRelationshipFragment on WpPrivateResidence_Flexiblecontent_FlexibleContent_PropertyHighlightRelationship {
    fieldGroupName
    accommodationListing {
      ... on WpAccommodation {
        id
        uri
        slug
        title
        content
        featuredImage {
          node {
            altText
            uri
            title
          }
        }
        content
        accommodationContent {
          fieldGroupName
          grid {
            tabs {
              fieldGroupName
              tabContent
              tabHeading
            }
            gallery {
              altText
              title
              imgixImage {
                url(imgixParams: { w: 1140, h: 570, q: 95, fit: "crop" })
              }
              uri
            }
            cta {
              target
              title
              url
            }
            content
            additionalCta {
              target
              title
              url
            }
            image {
              altText
            }
          }
        }
      }
    }
  }
`;
