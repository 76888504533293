import { Link, graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const GuidesLoop = ({ module, microSitename }) => {
  const imageFixLimit = 12;
  const [imageLimit, setImageLimit] = useState(imageFixLimit);
  const [guide, setGuide] = useState([[]]);
  const [fixLimit, setfixLimit] = useState(module.guides.length);
  const [gallery, setGallery] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [tabOption, setTabOption] = useState();
  const [tabContent, setTabContent] = useState();

  const categories = useStaticQuery(graphql`
    query guideCategories {
      allWpGuideType {
        nodes {
          name
          id
        }
      }
    }
  `);
  useEffect(() => {
    setGallery(() => {
      return module.guides.slice(0, imageLimit);
    });
  }, [imageLimit]);

  const customStyles = {
    content: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      top: '0',
      left: '0',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0',
      border: '0',
      borderRadius: '0',
      height: '100vh',
      width: '100%',
    },
  };

  function openModal(data) {
    document.body.classList.add('modal-open');
    setGuide(data);
    setIsOpen(true);
  }

  function closeModal() {
    document.body.classList.remove('modal-open');
    setIsOpen(false);
  }

  const onTabChange = (data) => {
    setTabOption(data.tabHeading);
    setTabContent(data.content);
  };

  return (
    <>
      <section
        id={`${module.extraId}`}
        className="filter-listing landscape py-30 ext-pb-120"
      >
        <div className="container-fluid">
          <div className="container m-auto">{/*  */}</div>
          <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10">
            {gallery.map((item, index) => {
              return (
                <div key={index} className="lg:w-6/12 w-full lg:px-20 fade-ani">
                  <a
                    onClick={() => {
                      setTabOption(
                        item.guideContent.guideContent[0].tabHeading
                      );
                      setTabContent(item.guideContent.guideContent[0].content);
                      openModal(item);
                    }}
                    className="cursor-pointer"
                  >
                    {item.featuredImage && (
                      <img
                        src={item?.featuredImage.node.imgixImage.fluid.srcWebp}
                        alt={item?.featuredImage.node.altText}
                        width="890"
                        height="512"
                        loading="lazy"
                      />
                    )}
                  </a>
                  <div className="img-content pt-30">
                    <a
                      onClick={() => {
                        setTabOption(
                          item.guideContent.guideContent[0].tabHeading
                        );
                        setTabContent(
                          item.guideContent.guideContent[0].content
                        );
                        openModal(item);
                      }}
                      className="cursor-pointer"
                    >
                      <h4 className="text-black-200">{item.title}</h4>

                      {item.guidesType.nodes[0]?.name && (
                        <span className="uppercase text-dark text-11 font-400 mt-10 inline-block font-preheaders">
                          {item.guidesType.nodes[0]?.name}
                        </span>
                      )}
                      {item.content && (
                        <div className="content my-15 max-w-600">
                          {parse(item.content)}
                        </div>
                      )}
                    </a>
                    <a
                      onClick={() => {
                        setTabOption(
                          item.guideContent.guideContent[0].tabHeading
                        );
                        setTabContent(
                          item.guideContent.guideContent[0].content
                        );
                        openModal(item);
                      }}
                      className="link-btn cursor-pointer"
                    >
                      Explore
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
          {fixLimit > gallery.length && gallery.length > 1 && (
            <div className="btn-custom text-center cursor-pointer justify-center lg:mt-80 mt-40">
              <a
                onClick={() => {
                  setImageLimit((imageLimit) => imageLimit + imageFixLimit);
                }}
                className="button button-transparent bg-[#e2e2e2]"
              >
                Load More
              </a>
            </div>
          )}
          {gallery.length === 0 && (
            <div className="no-record-found">
              <div className="container-fluid-md">
                <div className="bg-white shadow-card lg:py-120 py-60">
                  <div className="text-center">
                    <h2>Guides & Trackers are loading...</h2>
                    <div className="content mt-20 max-w-500 m-auto">
                      <p>
                        Please hang tight to learn more about the most qualified
                        guiding team in Africa.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className="img-modal img-modal-popup guides-popup !w-full">
          <div className="flex flex-wrap items-start bg-white shadow-card relative">
            <button
              className="modal-close absolute right-30 top-30 lgscreen:bg-white z-9 lgscreen:w-50 lgscreen:h-50 inline-block rounded-100 text-center"
              onClick={closeModal}
            >
              <img
                src={`/images/close2.png`}
                className="m-auto lgscreen:max-w-20"
                alt="close"
              />
            </button>
            <div className="lg:w-6/12 zigzag w-full">
              <div className="img-slider guide-popup-slider h-full relative">
                <Swiper
                  speed={1000}
                  effect={'fade'}
                  loop={true}
                  className="h-full"
                  navigation={false}
                  autoplay={{
                    delay: 3000,
                  }}
                  modules={[Navigation, Autoplay, Pagination, EffectFade]}
                >
                  {guide.guideContent?.guideGallery.length > 0 &&
                    guide.guideContent.guideGallery.map((item, i) => {
                      return (
                        <SwiperSlide className="h-full" key={i}>
                          <img
                            src={item?.imgixImage.fluid.srcWebp}
                            alt={item?.altText}
                            width="960"
                            className="h-full object-cover"
                            height="1000"
                            loading="lazy"
                          />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              <div className="pop-content flex flex-col justify-start items-start lg:py-50 py-30 lg:px-50 px-30 text-left">
                {module.extraId !== 'conservation-research-loop' && (
                  <span className="guide-and-trackers-text text-tan tracking-004em text-14 uppercase font-body font-400 mb-10">
                    Guides & Trackers
                  </span>
                )}
                <div className="title-black">
                  <h2>{guide.title}</h2>
                </div>
                {guide.guidesType && (
                  <p className="text-11 text-dark mt-10 uppercase">
                    {guide.guidesType.nodes[0]?.name}
                  </p>
                )}
                <div className="zigzag-tab max-w-600 mt-15">
                  <ul className="tabs space-x-8 mt-20 mb-20 border-black-100 border-opacity-10 inline-block">
                    {guide.guideContent &&
                      guide.guideContent.guideContent.map((item, i) => {
                        return (
                          <li
                            onClick={() => onTabChange(item)}
                            className={`tab-link cursor-pointer text-dark uppercase font-400 text-12 font-preheaders ${
                              tabOption == item.tabHeading ? 'tab-current' : ''
                            }`}
                          >
                            {item.tabHeading && parse(item.tabHeading)}
                          </li>
                        );
                      })}
                  </ul>
                </div>

                {tabContent && (
                  <div className="content w-full">{parse(tabContent)}</div>
                )}
                {guide.guideContent?.cta && (
                  <div className="btn-custom mt-20">
                    <Link
                      to={guide.guideContent.cta.url}
                      target={guide.guideContent.target}
                      className="button button-transparent"
                    >
                      {guide.guideContent.cta.title}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GuidesLoop;

export const GuidesLoopPropertyFragment = graphql`
  fragment GuidesLoopPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_GuidesLoop {
    # content
    fieldGroupName
    extraId
    guides {
      ... on WpGuide {
        id
        menuOrder
        guidesType {
          nodes {
            name
            id
          }
        }
        categories {
          nodes {
            name
            id
          }
        }
        featuredImage {
          node {
            altText
            imgixImage {
              fluid(
                maxWidth: 890
                maxHeight: 512
                imgixParams: { w: 890, h: 512, fit: "crop" }
              ) {
                srcWebp
              }
            }
            uri
            title
          }
        }
        content

        guideContent {
          guideContent {
            tabHeading
            fieldGroupName
            content
          }
          cta {
            title
            url
            target
          }
          guideGallery {
            altText
            title
            imgixImage {
              fluid(
                maxWidth: 960
                maxHeight: 1000
                imgixParams: { w: 960, h: 1000, fit: "crop" }
              ) {
                srcWebp
              }
            }
            uri
          }
        }
        title
        uri
      }
    }
  }
`;
