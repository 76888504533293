import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";

const MediaRelationship = ({ module }) => {
  return (
    <>
      <section
        className={`icon-with-bx media-relationship lg:pb-120 pb-60 ${
          module.extraClass ? module.extraClass : ""
        }`}
      >
        <div className="icon-with-bx-wrapper">
          <div className="container-fluid">
            <div className="flex flex-wrap lg:mx-minus-20 lgscreen:mx-minus-15 gap-y-20">
              {module.media.map((item, i) => {
                return (
                  <div key={i} className="lg:w-3/12 md:w-4/12 w-full lg:px-20 lgscreen:px-15 px-0">
                    <a href={item.awardLink.awardLink}>
                      <div className="  text-center">
                        <img
                          src={item.featuredImage.node.imgixImage.url}
                          alt=""
                          className="w-full"
                          loading="lazy"
                        />
                      </div>
                    </a>
                    <div className="award-info pt-20">
                      <a href={item.awardLink.awardLink}>
                        <h6 className="text-22 text-black-200">{item.title}</h6>
                      </a>
                      {item.content && (
                        <span className="uppercase text-11 text-dark">
                          {parse(item.content)}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MediaRelationship;

export const MediaRelationshipFragment = graphql`
  fragment MediaRelationshipFragment on WpProperty_Flexiblecontent_FlexibleContent_MediaRelationship {
    # content
    fieldGroupName
    property
    showFilter
    media {
      ... on WpInTheMedia {
        id
        title
        content
        awardLink {
          awardLink
          fieldGroupName
        }
        featuredImage {
          node {
            altText
            imgixImage {
              url(
                imgixParams: { w: 305, h: 305, q: 95, fit: "crop" })
            }
            title
          }
        }
      }
    }
  }
`;
