import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import React from 'react';
import Accordionlist from '../components/Accordionlist';
import AnchorTabs from '../components/AnchorTabs';
import AwardsRelationship from '../components/AwardsRelationship';
import CardCarousel from '../components/CardCarousel';
import ColumnListing from '../components/ColumnListing';
import ExperiencesSlider from '../components/ExperiencesSlider';
import FoundationBanner from '../components/FoundationBanner';
import FoundationLoop from '../components/FoundationLoop';
import FullWidthBanner from '../components/FullWidthBanner';
import Gallery from '../components/Gallery';
import GeneralContent from '../components/GeneralContent';
import GlobalGallery from '../components/GlobalGallery';
import GuidesLoop from '../components/GuidesLoop';
import ImageContent from '../components/ImageContent';
import ImageContentWithTab from '../components/ImageContentWithTab';
import Instagram from '../components/Instagram';
import IntroContent from '../components/IntroContent';
import LodgeMenu from '../components/LodgeMenu';
import MapContent from '../components/MapContent';
import MediaRelationship from '../components/MediaRelationship';
import MicrositeIntroContent from '../components/MicrositeIntroContent';
import OffersLoop from '../components/OffersLoop';
import PressReleases from '../components/PressReleases';
import PropertyHighlight from '../components/PropertyHighlight';
import PropertyHighlightRelationship from '../components/PropertyHighlightRelationship';
import RelatedCards from '../components/RelatedCards';
import RestaurantMenus from '../components/RestaurantMenus';
import StayLoop from '../components/StayLoop';
import SubMenu from '../components/SubMenu';
import TabbedCardCarousel from '../components/TabbedCardCarousel';
import TestimonialListing from '../components/TestimonialListing';
import UspListing from '../components/UspListing';

const RatesSection = loadable(() => import('../components/RatesSection'));

const ModuleMicroSite = (props) => {
  const modules = props.modules.flexibleContent;
  const post = props.post;
  const microSitename = props.microSitename;
  const { location } = props;
  const components = {
    SubMenu,
    LodgeMenu,
    FullWidthBanner,
    IntroContent,
    TabbedCardCarousel,
    FoundationBanner,
    ImageContent,
    TestimonialListing,
    RelatedCards,
    ImageContentWithTab,
    ExperiencesSlider,
    CardCarousel,
    Instagram,
    FoundationLoop,
    AwardsRelationship,
    OffersLoop,
    Gallery,
    Accordionlist,
    GuidesLoop,
    ColumnListing,
    GeneralContent,
    PropertyHighlightRelationship,
    MicrositeIntroContent,
    MediaRelationship,
    StayLoop,
    PropertyHighlight,
    UspListing,
    RestaurantMenus,
    GlobalGallery,
    PressReleases,
    RatesSection,
    AnchorTabs,
    MapContent,
  };

  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpProperty_Flexiblecontent_FlexibleContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                post,
                module,
                location,
                i,
                modules: modules,
                microSitename: microSitename,
              })
            );
          })
        : null}
    </>
  );
};

export default ModuleMicroSite;

export const ModuleMicroSiteFragmentFragment = graphql`
  fragment ModuleMicroSiteFragmentFragment on WpProperty {
    flexibleContent {
      flexibleContent {
        __typename
        ...FullWidthBannerPropertyFragment
        ...IntroContentPropertyFragment
        ...TabbedCardCarouselPropertyFragment
        ...ImageContentPropertyFragment
        ...RelatedPropertyCardsFragment
        ...TestimonialListingPropertyFragment
        ...FoundationBannerPropertyFragment
        ...CardCarouselPropertyFragment
        ...FoundationLoopPropertyFragment
        ...OffersLoopPropertyFragment
        ...GalleryPropertyFragment
        ...AccordionlistProperyFragment
        ...GuidesLoopPropertyFragment
        ...ColumnListingPropertyFragment
        ...GeneralContentPropertyFragment
        ...PropertyHightlightFragment
        ...MicrositeIntroContentFragment
        ...StayLoopFragment
        ...PropertyUspListingFragment
        ...ExperiencesSliderPropertyFragment
        ...ImageContentWithTabPropertyFragment
        ...PropertyHighlightRelationshipFragment
        ...InstagramFragment
        ...AwardsRelationshipProperyFragment
        ...MediaRelationshipFragment
        ...RestaurantMenusFragment
        ...GlobalGalleryPropertyFragment
        ...PressReleasesPropertyFragment
        ...RatesSectionPropertyFragment
        ...AnchorTabsPropertyFragment
        ...MapContentPropertyFragment
      }
    }
  }
`;
