import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const AnchorTabs = ({ module }) => {
  return (
    <section className="anchor-tabs flex flex-col items-center mx-20">
      {module.heading && (
        <h2 className="title-black text-center max-w-[505px] pt-40 mt-40 mdscreen:mt-10">
          {module.heading}
        </h2>
      )}
      <div className="anchor-tabs__inner flex justify-center lgscreen:justify-start py-30 overflow-auto w-full">
        {module.anchorLinks?.length > 0 &&
          module.anchorLinks?.map((item, i) => {
            return (
              <a
                href={item.anchorLink.url}
                className="anchor-tabs__tab px-[2px] py-[10px] mx-15 whitespace-nowrap"
              >
                {item.anchorLink.title}
              </a>
            );
          })}
      </div>
    </section>
  );
};

export default AnchorTabs;

export const AnchorTabsPropertyFragment = graphql`
  fragment AnchorTabsPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_AnchorTabs {
    heading
    anchorLinks {
      anchorLink {
        url
        title
        target
      }
    }
  }
`;
