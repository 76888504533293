import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-datepicker/dist/react-datepicker.css";

const SubMenu = (props) => {
  const [currentURL, setcurrentURL] = useState("");
  useEffect(() => {
    setcurrentURL(window.location.pathname);
  });

  const link_url = {
    "Birkenhead House": "birkenhead-house",
    "La Residence": "la-residence",
    "Royal Malewane": "royal-malewane",
    "The Silo Hotel": "the-silo-menu",
  };

  const data = useStaticQuery(graphql`
    {
      allWpMenu(
        filter: {
          name: {
            in: [
              "Birkenhead House"
              "La Residence"
              "Royal Malewane"
              "The Silo Menu"
            ]
          }
        }
      ) {
        nodes {
          name
          id
          slug
          menuItems {
            nodes {
              childItems {
                nodes {
                  id
                  label
                  uri
                  path
                  parentId
                }
              }
              url
              path
              target
              label
              id
              parentId
            }
          }
        }
      }
    }
  `);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [hoveredMenuItemId, setHoveredMenuItemId] = useState(false);

  const checkScrollDirectionIsUp = (event) => {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  const handleScroll = (event) => {
    const position = window.pageYOffset;

    var element = document.querySelector("#subMenu");
    var position1 = element.getBoundingClientRect();

    setScrollPosition(position);

    let scrolledUp = checkScrollDirectionIsUp(event);
    const stickySubMenu = document.querySelector('.top-filter--sticky');

    if (position > 100) {
      document.body.classList.add("scroll-down");
    }
    if (position <= 100) {
      document.body.classList.remove("scroll-down");
    }
    if (position > 700) {
      stickySubMenu.style.display = "block";

      if (scrolledUp) {
        setTimeout(function(){
          stickySubMenu.style.transform = 'translate(0)'; 
        }, 150);
      } else {
        stickySubMenu.style.transform = 'translateY(-100%)';
        setTimeout(function(){
          stickySubMenu.style.display = "none";
        }, 150);
      }
    }
    if (position <= 700) {
      // stickySubMenu.style.opacity = '0';
      stickySubMenu.style.transform = 'translateY(-100%)';
      setTimeout(function(){
        stickySubMenu.style.display = "none";
        stickySubMenu.style.transform = 'translateY(-100%)';
        // stickySubMenu.style.opacity = '100%';
      }, 200);
    }
  };

  useEffect(() => {
    document.body.classList.add("sub-site");
    window.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const menus = data.allWpMenu.nodes.filter(function (value) {
    return value.slug === link_url[props.microSitename];
  });
  return (
    <>
      <section id="subMenu" className="top-filter relative z-[997] lg:py-31 py-15">
        <div className="container-fluid">
          <div className="top-filter-info xl:flex hidden flex-wrap justify-between border-b-1 border-black-100 border-opacity-10 px-15">
          {menus &&
            menus[0]?.menuItems.nodes.filter(menu => menu.parentId === null).map((menu, i) => {  
              return (
                <li 
                  key={i}
                  onMouseEnter={() => setHoveredMenuItemId(menu.id)}
                  onMouseLeave={() => setHoveredMenuItemId(null)}
                >
                  <Link
                    to={menu.path}
                    className={` uppercase text-12 laptop:text-10 text-dark font-400 pb-15 hover:text-gold underline-nav-link ${
                      currentURL.includes(menu.path.replace(/\/$/, "")) ===
                      true
                        ? "active underline-menu-item"
                        : ""
                    }`}
                  >
                    {menu.label}
                  </Link>
                  {/* Check if menu item has child items */}
                  {menu.childItems && menu.childItems.nodes.length > 0 && (
                    <ul 
                      className="submenu_child"
                      style={{
                        "listStyleType": "none",
                        "display": hoveredMenuItemId === menu.id ? "flex" : "none",
                        "justifyContent": "center",
                        "columnGap": "2rem",
                        "position": "absolute",
                        "background": "#f2f2f2",
                        "width": "100%",
                        "left": "0",
                        "marginTop": "1px",
                        "paddingTop": "10px"
                      }}
                    >
                      {menu.childItems.nodes.map((childMenu, j) => (
                        <li key={j}>
                          <Link
                            to={childMenu.path}
                            className={` uppercase text-12 laptop:text-10 text-dark font-400 pb-15 hover:text-gold underline-nav-link ${
                              currentURL.includes(childMenu.path.replace(/\/$/, "")) ===
                              true
                                ? "active underline-menu-item"
                                : ""
                            }`}
                          >
                            {childMenu.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </div>
          <div className="top-filter-mobile xl:hidden block">
            <select
              onChange={(e) => {
                navigate(e.target.value);
              }}
            >
              {menus &&
                menus[0]?.menuItems.nodes.filter(menu => menu.parentId === null).map((menu, i) => {
                  return <option key={i} value={menu.path}> {menu.label}</option>;
                })}
            </select>
          </div>
        </div>
      </section>
      <section style={{display:'none'}} className="top-filter top-filter--sticky lg:pt-31 pt-15">
        <div className="container-fluid">
          <div className="top-filter-info xl:flex hidden flex-wrap justify-between border-b-1 border-black-100 border-opacity-10 px-15">
          {menus &&
            menus[0]?.menuItems.nodes.filter(menu => menu.parentId === null).map((menu, i) => {
              
              return (
                <li 
                  key={i}
                  onMouseEnter={() => setHoveredMenuItemId(menu.id)}
                  onMouseLeave={() => setHoveredMenuItemId(null)}
                >
                  <Link
                    to={menu.path}
                    className={` uppercase text-12 laptop:text-10 text-dark font-400 pb-15 hover:text-gold underline-nav-link ${
                      currentURL.includes(menu.path.replace(/\/$/, "")) ===
                      true
                        ? "active underline-menu-item"
                        : ""
                    }`}
                  >
                    {menu.label}
                  </Link>
                  {/* Check if menu item has child items */}
                  {menu.childItems && menu.childItems.nodes.length > 0 && (
                    <ul 
                      className="submenu_child"
                      style={{
                        "listStyleType": "none",
                        "display": hoveredMenuItemId === menu.id ? "flex" : "none",
                        "justifyContent": "center",
                        "columnGap": "2rem",
                        "position": "absolute",
                        "background": "#ffffff",
                        "width": "100%",
                        "left": "0",
                        "marginTop": "1px",
                        "paddingTop": "10px"
                      }}
                    >
                      {menu.childItems.nodes.map((childMenu, j) => (
                        <li key={j}>
                          <Link
                            to={childMenu.path}
                            className={` uppercase text-12 laptop:text-10 text-dark font-400 pb-15 hover:text-gold underline-nav-link ${
                              currentURL.includes(childMenu.path.replace(/\/$/, "")) ===
                              true
                                ? "active underline-menu-item"
                                : ""
                            }`}
                          >
                            {childMenu.label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </div>
          <div className="top-filter-mobile xl:hidden block">
            <select
              onChange={(e) => {
                navigate(e.target.value);
              }}
            >
              {menus &&
                menus[0].menuItems.nodes.map((menu, i) => {
                  return <option   key={i} value={menu.path}> {menu.label}</option>;
                })}
            </select>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubMenu;
