import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const PropertyHightlight = ({ module }) => {
  const [tabOption, setTabOption] = useState(
    module?.tabs?.length > 0 ? module?.tabs[0]?.tabHeading : ''
  );

  return (
    <>
      <section className="imgoverlay-wrapper lg:py-60 py-30">
        <div className="container-fluid-lg lg:space-y-32 space-y-14">
          <div className="imgoverlay-grid fade-img">
            <Swiper
              className="h-[70vw] md:h-[50vw] lg:h-[40vw]"
              loop={true}
              lazy={true}
              speed={500}
              slidesPerView={1}
              effect={'fade'}
              navigation={true}
              modules={[Navigation, EffectFade]}
            >
              {module?.gallery?.map((item, index) => (
                <SwiperSlide
                  key={index.toString()}
                  className="h-[70vw] md:h-[50vw] lg:h-[40vw]"
                >
                  <img
                    className="h-[70vw] md:h-[50vw] lg:h-[40vw] object-cover object-center w-full"
                    src={item.imgixImage.url}
                    width="1140"
                    height="576"
                    loading="lazy"
                    alt={item?.altText}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="imgoverlay-content lg:px-40 lg:mt-minus-100">
              <div className="bg-white z-9 relative lg:p-50  p-25 shadow-card">
                <h3>{module?.heading}</h3>
                <div className="flex flex-wrap justify-between">
                  <div className="lg:w-6/12 w-full">
                    <div className="custom-tabs hidden mdscreen:flex mt-15">
                      {module?.tabs?.length > 0 &&
                        module.tabs.map((item, i) => {
                          return (
                            <div
                              key={i}
                              onClick={(e) => {
                                setTabOption(item.tabHeading);
                              }}
                              className={`custom-tabs__tab uppercase text-14 text-dark font-400 px-10 py-10 border-b-1 border-b-[rgba(229,229,229,0.8)] ${
                                item?.tabHeading === tabOption
                                  ? 'tab-current'
                                  : ''
                              }`}
                            >
                              {item.tabHeading}
                            </div>
                          );
                        })}
                    </div>
                    <div className="left-content lg:pr-40">
                      <ul className="tabs mt-15 mdscreen:hidden border-black-100 border-opacity-10 inline-block">
                        {module?.tabs?.length > 0 &&
                          module?.tabs.map((item) => (
                            <li
                              onClick={() => {
                                setTabOption(item?.tabHeading);
                              }}
                              className={`tab-link cursor-pointer text-dark uppercase font-400 text-12 ${
                                tabOption === item?.tabHeading
                                  ? 'tab-current'
                                  : ''
                              }`}
                            >
                              {item?.tabHeading}
                            </li>
                          ))}
                      </ul>
                      <div className="tabbox mt-20">
                        <div id="overview" className="tab-content global-list">
                          {module?.tabs?.length > 0 &&
                            module?.tabs.map((item) => {
                              return (
                                item?.tabHeading === tabOption && (
                                  <div className="content !text-[13px]">
                                    {item?.tabContent &&
                                      parse(item?.tabContent)}
                                  </div>
                                )
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-6/12 w-full">
                    <div className="right-content global-list lg:pl-60 relative lg:pt-0 pt-20">
                      <div className="right-content__text font-heading lgscreen:mt-20 font-300">
                        {module?.content && parse(module.content)}
                      </div>
                      <div className="btn-custom space-x-2 mt-20 w-full md:w-auto !justify-start">
                        {module?.cta && (
                          <Link
                            to={module?.cta.url}
                            className="button button-transparent"
                          >
                            {module?.cta.title}
                          </Link>
                        )}
                        {module?.additionalCta && (
                          <Link
                            to={module?.additionalCta.url}
                            className="link-btn"
                          >
                            {module?.additionalCta.title}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PropertyHightlight;

export const PrivateResidencePropertyHightlightFragment = graphql`
  fragment PrivateResidencePropertyHightlightFragment on WpPrivateResidence_Flexiblecontent_FlexibleContent_PropertyHighlight {
    content
    fieldGroupName
    heading
    additionalCta {
      target
      title
      url
    }
    cta {
      target
      title
      url
    }
    gallery {
      sourceUrl
      altText
      imgixImage {
        url(imgixParams: { w: 1140, h: 570, q: 95, fit: "crop" })
      }
    }
    tabs {
      fieldGroupName
      tabContent
      tabHeading
    }
  }
`;
export const PropertyHightlightFragment = graphql`
  fragment PropertyHightlightFragment on WpProperty_Flexiblecontent_FlexibleContent_PropertyHighlight {
    content
    fieldGroupName
    heading
    additionalCta {
      target
      title
      url
    }
    cta {
      target
      title
      url
    }
    gallery {
      sourceUrl
      altText
      imgixImage {
        url(imgixParams: { w: 1140, h: 570, q: 95, fit: "crop" })
      }
    }
    tabs {
      fieldGroupName
      tabContent
      tabHeading
    }
  }
`;
