import { Link, graphql } from 'gatsby';
import React, { useState } from 'react';

const RestaurantMenus = ({ module }) => {
  const [tabOption, setTabOption] = useState(
    module.restaurantMenus ? module.restaurantMenus[0].courseName : ''
  );

  return (
    <>
      <section
        className={`resturant-menu lg:mb-120 mb-60  ${
          module.extraClass ? module.extraClass : ''
        }`}
      >
        <div className="container-fluid">
          <ul className="flex justify-center slider-tabs pb-60 mdscreen:pb-30">
            {module.restaurantMenus &&
              module.restaurantMenus.map((item, i) => {
                return (
                  <li
                    onClick={() => {
                      setTabOption(item?.courseName);
                    }}
                    className={`${
                      item?.courseName === tabOption ? 'active' : ''
                    } uppercase cursor-pointer text-15 text-dark font-400`}
                  >
                    {item?.courseName}
                  </li>
                );
              })}
          </ul>

          {module.restaurantMenus &&
            module.restaurantMenus.map((item, i) => {
              return (
                tabOption === item.courseName && (
                  <div className="container-fluid-md mdscreen:px-0">
                    <div className="menu-grid bg-white shadow-card lg:py-80 py-40 lg:px-100 px-20">
                      {item.menuLayout.map((item2, i) => {
                        return (
                          <>
                            <div className="menu-heading">
                              <div className="flex flex-wrap space-y-4 items-end justify-between border-b-1 border-opacity-80 border-gray-1000 pb-30">
                                <div className="content-left">
                                  <span className="uppercase text-16 mdscreen:text-14 text-dark">
                                    {item2.preheading}
                                  </span>
                                  <h2 className="mt-10">{item.courseName}</h2>
                                </div>
                                <div className="content-download">
                                  <Link
                                    className="flex items-center uppercase text-12"
                                    to={item2.menuPdf.url}
                                    target={item2.menuPdf.target}
                                  >
                                    <img
                                      className="max-w-[15px] mr-10"
                                      src="../../../../images/download.png"
                                    />
                                    {item2.menuPdf.title}
                                  </Link>
                                </div>
                              </div>
                            </div>
                            {item2.menuItems.map((item3, i) => {
                              return (
                                <div className="menu-row pt-30">
                                  <h2 className="text-32 mdscreen:text-22 mb-10">
                                    {item3.menuHeading}
                                  </h2>
                                  <div className="flex flex-wrap">
                                    {item3.meals.map((item3, i) => {
                                      return (
                                        <div className="lg:w-6/12 w-full pr-50">
                                          <div className="flex justify-between">
                                            <h5 className="text-20 mdscreen:text-16 text-black-200 font-body">
                                              {item3.mealName}
                                            </h5>
                                            {item3.mealPrice && (
                                              <span className="text-16">
                                                {item3.mealPrice}
                                              </span>
                                            )}
                                          </div>
                                          <p className="text-15 text-dark mb-15 italic">
                                            {item3.mealDescription}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </section>
    </>
  );
};

export default RestaurantMenus;

export const RestaurantMenusFragment = graphql`
  fragment RestaurantMenusFragment on WpProperty_Flexiblecontent_FlexibleContent_RestaurantMenus {
    # content
    fieldGroupName
    restaurantMenus {
      courseName
      fieldGroupName
      menuLayout {
        fieldGroupName
        heading
        menuItems {
          fieldGroupName
          meals {
            fieldGroupName
            mealDescription
            mealName
            mealPrice
          }
          menuHeading
        }
        menuPdf {
          target
          title
          url
        }
        preheading
      }
    }
  }
`;
