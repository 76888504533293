import { graphql, Link } from 'gatsby';
import React from 'react';
import { Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const StayLoop = ({ module }) => {
  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.full-bx-slider .propertySliderList'
    ).swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector(
      '.full-bx-slider .propertySliderList'
    ).swiper;
    swiper.slidePrev();
  };

  const handleNextClickStayDesktop = () => {
    const swiper = document.querySelector(
      '.full-bx-slider .propertySliderListDesktop'
    ).swiper;
    swiper.slideNext();
  };
  const handlePrevClickStayDesktop = () => {
    const swiper = document.querySelector(
      '.full-bx-slider .propertySliderListDesktop'
    ).swiper;
    swiper.slidePrev();
  };

  return (
    <>
      <section className="full-bx-slider full-bx-slider-content property-slider-arrow bg-white lg:py-60 py-60">
        <div className="title-black smscreen:mb-30 text-center mb-30 px-20">
          <h2>{module.heading}</h2>
        </div>
        <div>
          <div className="tabbed-desktop hidden md:block">
            {module?.accommodation?.length > 0 && (
              <Swiper
                className="propertySliderListDesktop"
                loop={true}
                lazy={true}
                slidesPerView={2.75}
                spaceBetween={20}
                pagination={{
                  type: 'progressbar',
                }}
                allowTouchMove={true}
                centeredSlides={true}
                watchSlidesProgress={true}
                navigation={false}
                speed={1000}
                modules={[Navigation, Pagination, Thumbs]}
              >
                {module.accommodation.map((item, i) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <div className="card-bx text-center">
                        <div className="relative">
                          <img
                            src={item.featuredImage?.node.imgixImage.url}
                            alt={item.featuredImage?.node?.altText || ''}
                            className="w-full"
                            loading="lazy"
                          />
                        </div>
                        <div className="content pt-30 max-w-[530px] m-auto">
                          <Link to={module?.cta.url}>
                            <h3>{item.title}</h3>
                            {item.accommodationContent && (
                              <p>
                                {item.accommodationContent.shortDescription}
                              </p>
                            )}
                          </Link>
                          {module?.cta && (
                            <div className="flex flex-wrap items-center pt-10 justify-center mt-20 gap-x-8">
                              <Link
                                to={module?.cta.url}
                                className="link-btn mb-[35px] cursor-pointer "
                              >
                                {module?.cta.title}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
                <div className="swiper-arrow  flex items-center justify-between w-full visible z-9 translate-y-minus_50 ">
                  <a
                    className="button-prev cursor-pointer"
                    onClick={handlePrevClickStayDesktop}
                  ></a>
                  <a
                    className="button-next cursor-pointer"
                    onClick={handleNextClickStayDesktop}
                  ></a>
                </div>
              </Swiper>
            )}
          </div>
          <div className="tabbed-mobile md:hidden">
            {module?.accommodation?.length > 0 && (
              <Swiper
                className="propertySliderList"
                loop={true}
                slidesPerView={1.5}
                spaceBetween={20}
                pagination={{
                  type: 'progressbar',
                }}
                allowTouchMove={true}
                centeredSlides={true}
                watchSlidesProgress={true}
                navigation={false}
                speed={1000}
                modules={[Navigation, Pagination, Thumbs]}
              >
                {module.accommodation.map((item, i) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <div className="card-bx text-center">
                        <div className="relative">
                          <img
                            src={item.featuredImage?.node.imgixImage.url}
                            alt={item.featuredImage?.node?.altText || ''}
                            className="w-full"
                            loading="lazy"
                          />
                        </div>
                        <div className="content pt-30 max-w-500 m-auto mdscreen:px-20">
                          <Link to={module?.cta.url}>
                            <h3 className="text-[24px] mb-10">{item.title}</h3>
                          </Link>
                          {module?.cta && (
                            <div className="flex flex-wrap items-center pt-10 justify-center gap-x-8">
                              <Link
                                to={module?.cta.url}
                                className="link-btn mb-[35px] cursor-pointer "
                              >
                                {module?.cta.title}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
                <div className="swiper-arrow  flex items-center justify-between w-full visible z-9 translate-y-minus_50 px-20">
                  <a
                    className="button-prev cursor-pointer"
                    onClick={handlePrevClickStay}
                  ></a>
                  <a
                    className="button-next cursor-pointer"
                    onClick={handleNextClickStay}
                  ></a>
                </div>
              </Swiper>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default StayLoop;

export const StayLoopFragment = graphql`
  fragment StayLoopFragment on WpProperty_Flexiblecontent_FlexibleContent_StayLoop {
    # content
    fieldGroupName
    heading
    style
    cta {
      url
      title
      target
    }
    accommodation {
      ... on WpAccommodation {
        id
        uri
        title
        content
        featuredImage {
          node {
            altText
            imgixImage {
              url(imgixParams: { w: 515, h: 310, q: 95, fit: "crop" })
            }
            uri
            title
          }
        }

        accommodationContent {
          fieldGroupName
          shortDescription
          slider {
            content
          }
        }
      }
    }
  }
`;
