import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

const MapContent = ({ module }) => {
  return (
    <section className="map-content bg-[#ffffff] m-50 lgscreen:m-30 mdscreen:m-20 mb-80 lgscreen:mb-60 mdscreen:mb-40">
      <div className="map-content__map">
        <img
          src={module.image.mediaItemUrl}
          alt={module.image.altText}
          className="map-img object-fit h-full w-full"
        />
      </div>
      <div className="map-content__content flex flex-col items-center py-50 mdscreen:py-35">
        <div className="title-black text-center">
          <h3>{module.heading}</h3>
        </div>
        <div className="content text-center mt-15 mx-20">
          <p className="!text-[#666366] !text-13 !font-300">
            {module.subheading}
          </p>
        </div>
        <div className="details flex mdscreen:flex-col mdscreen:items-center pt-30 mdscreen:pt-20 pb-10 mdscreen:pb-5">
          {module.details?.length > 0 &&
            module.details?.map((item, i) => {
              return (
                <div className="detail mx-15">
                  <span className="detail__label text-[#666366] text-14 font-300">
                    {item.detailLabel}
                  </span>
                  <a
                    href={item.detailValue.url}
                    className="detail__value text-[#B09244] text-14 font-300 transition-all duration-150 ease-out"
                  >
                    {item.detailValue.title}
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default MapContent;

export const MapContentPropertyFragment = graphql`
  fragment MapContentPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_MapContent {
    heading
    image {
      mediaItemUrl
    }
    subheading
    details {
      detailLabel
      detailValue {
        url
        title
      }
    }
  }
`;
