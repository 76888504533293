import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Seo from '../components/Seo/Seo';
import LayoutMicro from '../components/layout-micro';
import MicroSite from '../modules/MicroSite';

const PageTemplate = (props) => {
  const post = props.data.post;
  const { location } = props;
  const microSitename = props.pageContext.categories
    ? props.pageContext.categories?.nodes[0]?.name
    : '';
  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      document.body.classList.add('scroll-down');
    }
    if (window.pageYOffset <= 100) {
      document.body.classList.remove('scroll-down');
    }
    if (window.pageYOffset > 10) {
      document.body.classList.add('header-sticky');
    }
    if (window.pageYOffset <= 10) {
      document.body.classList.remove('header-sticky');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <LayoutMicro
        microSitename={microSitename}
        props={props}
        location={location}
      >
        <Seo seo={post.seo} />
        <MicroSite
          post={post}
          microSitename={microSitename}
          modules={post.flexibleContent}
          location={location}
        />
      </LayoutMicro>
    </>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query PropertyById($id: String!) {
    post: wpProperty(id: { eq: $id }) {
      id
      title
      uri
      parent {
        ... on WpPage {
          id
          uri
          title
        }
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
      seo {
        fullHead
        metaDesc
        canonical
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...ModuleMicroSiteFragmentFragment
    }
  }
`;
